<template>
  <div class="">
    <h1 class="title">Календарь</h1>

    <AddButton
      :is-show-download="true"
      @click="handleAdd"
      @download="handleDownload"
    />

    <section>
      <b-table
        :data="data"
        :loading="loading"
        :mobile-cards="false"
        :paginated="isPaginated"
        :pagination-simple="isPaginationSimple"
        :pagination-position="paginationPosition"
        backend-pagination
        backend-filtering
        :total="total"
        :per-page="perPage"
        aria-page-label="Page"
        aria-current-label="Current page"
        backend-sorting
        :default-sort-direction="defaultSortOrder"
        :default-sort="[sortField, sortOrder]"
        :debounce-search="500"
        @page-change="onPageChange"
        @sort="onSort"
        @filters-change="onFiltersChange"
      >
        <b-table-column v-slot="props" label="№">
          {{ props.index + 1 + perPage * (page - 1) }}
        </b-table-column>
        <b-table-column field="Title" label="Название" sortable searchable>
          <template>
            <b-input placeholder="Поиск..." icon="magnify" size="is-small" />
          </template>
          <template v-slot="props">
            <router-link
              :to="{ name: 'SportEventShow', params: { id: props.row.id } }"
            >
              {{ props.row.Title }}
            </router-link>
          </template>
        </b-table-column>

        <b-table-column
          field="DateStart"
          label="Начало"
          sortable
          searchable
          centered
        >
          <template #searchable="props">
            <b-datepicker
              v-model="props.filters[props.column.field]"
              :locale="locale"
              icon="calendar-today"
              :icon-right="
                props.filters[props.column.field] ? 'close-circle' : ''
              "
              icon-right-clickable
              @icon-right-click="props.filters[props.column.field] = null"
            />
          </template>
          <template v-slot="props">
            {{ props.row.DateStart | date }}
          </template>
        </b-table-column>

        <b-table-column
          field="DateFinish"
          label="Окончание"
          sortable
          searchable
          centered
        >
          <template #searchable="props">
            <b-datepicker
              v-model="props.filters[props.column.field]"
              :locale="locale"
              icon="calendar-today"
              :icon-right="
                props.filters[props.column.field] ? 'close-circle' : ''
              "
              icon-right-clickable
              @icon-right-click="props.filters[props.column.field] = null"
            />
          </template>
          <template v-slot="props">
            {{ props.row.DateFinish | date }}
          </template>
        </b-table-column>

        <b-table-column
          v-slot="props"
          field="Location"
          label="Место проведения"
          sortable
          searchable
        >
          {{ props.row.Location }}
        </b-table-column>

        <b-table-column
          v-slot="props"
          field="category_sports"
          label="Вид спорта"
          sortable
          searchable
        >
          {{ props.row.category_sports | sports }}
        </b-table-column>

        <b-table-column
          v-slot="props"
          field="published_at"
          label="Статус"
          sortable
          centered
        >
          <b-icon
            v-if="props.row.published_at"
            icon="lock-open-variant"
            size="is-small"
            type="is-success"
          />
          <b-icon v-else icon="lock" size="is-small" type="is-warning" />
        </b-table-column>
      </b-table>
    </section>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import { LOCALE, Resource } from "@/common/const/common";
import AddButton from "@/common/components/AddButton";
import { getQueryFilters } from "../utils";
import { defaultSportEvent, EVENTS_PER_PAGE } from "../const";
import SportEventForm from "./SportEventForm";
import AppDownloadRangeForm from "@/common/components/AppDownloadRangeForm";

export default {
  name: "SportEventsIndex",
  components: { AddButton },
  filters: {
    sports: function (values) {
      return values?.length ? values.map((item) => item.Title).join(", ") : "";
    },
  },
  data() {
    return {
      isPaginated: true,
      isPaginationSimple: false,
      isPaginationRounded: false,
      paginationPosition: "bottom",
      locale: LOCALE,

      data: [],
      total: 0,
      loading: false,
      sortField: "DateStart",
      sortOrder: "desc",
      defaultSortOrder: "asc",
      page: 1,
      perPage: EVENTS_PER_PAGE,

      entityFilters: [],
      dates: [],
    };
  },
  computed: {
    ...mapGetters({
      isEditor: "auth/isEditor",
      user: "auth/getUser",
    }),
  },
  async mounted() {
    await this.loadAsyncData();
    document.title = "Календарь";
  },
  methods: {
    async handleAdd() {
      this.$buefy.modal.open({
        parent: this,
        component: SportEventForm,
        props: { sportEvent: defaultSportEvent },
        events: { close: this.handleClose },
        hasModalCard: true,
        fullScreen: true,
      });
    },
    async handleClose(sportEvent) {
      if (sportEvent?.id) {
        await this.$router.push({
          name: "SportEventShow",
          params: { id: sportEvent.id },
        });
      }
    },

    async handleDownload() {
      const modal = this.$buefy.modal.open({
        parent: this,
        component: AppDownloadRangeForm,
        props: {
          url: "/reports/events-report",
          title: "Скачать список соревнований",
        },
        hasModalCard: true,
        fullScreen: false,
      });
      // modal.$on("close", () => console.log("close"));
    },
    onPageChange(page) {
      this.page = page;
      this.loadAsyncData();
    },

    async onFiltersChange(filters) {
      this.entityFilters = await getQueryFilters(filters, this.$dayjs);
      await this.loadAsyncData();
    },

    onSort(field, order) {
      this.sortField = field;
      this.sortOrder = order;
      this.loadAsyncData();
    },

    async loadAsyncData() {
      const start = (this.page - 1) * this.perPage;
      const params = [
        `find-to-index-local?_sort=${this.sortField}:${this.sortOrder}`,
        `_limit=${this.perPage}`,
        `_start=${start}`,
        ...this.entityFilters,
      ].join("&");

      const sportType = this.isEditor
        ? `category_sport_type=${this.user.sportType}`
        : "";

      const countQuery = [`count?${sportType}`, ...this.entityFilters].join(
        "&"
      );

      this.loading = true;

      try {
        this.data = (await this.$api[Resource.SPORT_EVENTS].get(params)) || [];
        this.loading = false;
        this.total = await this.$api[Resource.SPORT_EVENTS].get(countQuery);
      } catch (e) {
        this.data = [];
        this.total = 0;
        this.loading = false;
      }
    },
  },
};
</script>
